import { FiX } from 'react-icons/fi'; // Ícone de fechar modal
import './modal.css';

export default function Modal({ conteudo, close }) {
  return (
    <div className="modal">
      <div className="container">
        {/* Botão de fechar o modal */}
        <button className="close" onClick={close}>
          <FiX size={25} color="#FFF" />
          Voltar
        </button>

        <main>
          <h2>Detalhes do chamado</h2>

          {/* Exibição do número do ticket */}
          <div className="row">
            <span>
              Ticket: <i>{conteudo.ticketNumber}</i>
            </span>
          </div>

          {/* Exibição do cliente do chamado */}
          <div className="row">
            <span>
              Cliente: <i>{conteudo.cliente}</i>
            </span>
          </div>

          {/* Exibição do assunto e data de criação do chamado */}
          <div className="row">
            <span>
              Assunto: <i>{conteudo.assunto}</i>
            </span>
            <span>
              Cadastrado em: <i>{conteudo.createdFormat}</i>
            </span>
          </div>

          {/* Exibição do status do chamado, com cor de fundo condicional */}
          <div className="row">
            <span>
              Status:
              <i
                className="status-badge"
                style={{
                  color: '#FFF',
                  backgroundColor:
                    conteudo.status === 'Aberto' ? '#5cb85c' : '#999',
                }}
              >
                {conteudo.status}
              </i>
            </span>
          </div>

          {/* Exibe o complemento apenas se o conteúdo estiver preenchido */}
          {conteudo.complemento !== '' && (
            <>
              <h3>Complemento</h3>
              <p>{conteudo.complemento}</p>
            </>
          )}
        </main>
      </div>
    </div>
  );
}
