import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyCpZNE1MatuORTnFrTfWrtdHlNT_M8dsUA',

  authDomain: 'tickets-a77b4.firebaseapp.com',

  projectId: 'tickets-a77b4',

  storageBucket: 'tickets-a77b4.firebasestorage.app',

  messagingSenderId: '556185270957',

  appId: '1:556185270957:web:738c287982f60e57ace424',

  measurementId: 'G-22T3S4JY3R',
};

const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export { auth, db, storage };
