import { useState, useEffect, useContext } from 'react';
import Header from '../../components/Header';
import Title from '../../components/Title';
import Footer from '../../components/Footer';
import { FiPlusCircle } from 'react-icons/fi';
import { AuthContext } from '../../contexts/auth';
import { db } from '../../services/firebaseConnection';
import {
  collection,
  getDocs,
  getDoc,
  doc,
  addDoc,
  updateDoc,
  query,
  orderBy,
  limit,
} from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import './new.css';

const listRef = collection(db, 'customers');

export default function New() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const [customers, setCustomers] = useState([]);
  const [loadCustomer, setLoadCustomer] = useState(true);
  const [customerSelected, setCustomerSelected] = useState(0);
  const [complemento, setComplemento] = useState('');
  const [assunto, setAssunto] = useState('Suporte');
  const [status, setStatus] = useState('Aberto');
  const [idCustomer, setIdCustomer] = useState(false);

  // Estado para gerenciar o número sequencial do ticket e exibição do modal
  const [ticketNumber, setTicketNumber] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // useEffect para carregar clientes e definir o próximo número de ticket
  useEffect(() => {
    async function loadCustomersAndTicketNumber() {
      try {
        const snapshot = await getDocs(listRef);
        let lista = [];
        snapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            nomeFantasia: doc.data().nomeFantasia,
          });
        });
        setCustomers(
          lista.length > 0 ? lista : [{ id: '1', nomeFantasia: 'FREELA' }],
        );
        setLoadCustomer(false);

        if (id) {
          loadId(lista);
        }

        // Busca o maior número de ticket
        const ticketQuery = query(
          collection(db, 'chamados'),
          orderBy('ticketNumber', 'desc'),
          limit(1),
        );
        const ticketSnapshot = await getDocs(ticketQuery);
        if (!ticketSnapshot.empty) {
          // Define o próximo número sequencial
          const lastTicket = ticketSnapshot.docs[0].data().ticketNumber;
          setTicketNumber(lastTicket + 1);
        } else {
          // Se não houver tickets, começa do 1
          setTicketNumber(1);
        }
      } catch (error) {
        console.log('Erro ao buscar clientes ou número de ticket:', error);
        setLoadCustomer(false);
        setCustomers([{ id: '1', nomeFantasia: 'FREELA' }]);
      }
    }

    loadCustomersAndTicketNumber();
  }, [id]);

  async function loadId(lista) {
    const docRef = doc(db, 'chamados', id);
    await getDoc(docRef)
      .then((snapshot) => {
        setAssunto(snapshot.data().assunto);
        setStatus(snapshot.data().status);
        setComplemento(snapshot.data().complemento);

        let index = lista.findIndex(
          (item) => item.id === snapshot.data().clienteId,
        );
        setCustomerSelected(index);
        setIdCustomer(true);
      })
      .catch((error) => {
        toast.error('Erro ao buscar dados do chamado.');
        console.log(error);
        setIdCustomer(false);
      });
  }

  function handleOptionChange(e) {
    setStatus(e.target.value);
  }

  function handleChangeSelect(e) {
    setAssunto(e.target.value);
  }

  function hnadleChangeCustomer(e) {
    setCustomerSelected(e.target.value);
  }

  async function handleRegister(e) {
    e.preventDefault();

    // Se for edição, atualiza o chamado existente
    if (idCustomer) {
      const docRef = doc(db, 'chamados', id);
      await updateDoc(docRef, {
        assunto,
        cliente: customers[customerSelected].nomeFantasia,
        clienteId: customers[customerSelected].id,
        complemento,
        status,
      })
        .then(() => {
          toast.info('Chamado editado com sucesso!');
          navigate('/dashboard');
        })
        .catch((error) => {
          toast.error('Erro ao atualizar o chamado.');
          console.log(error);
        });
      return;
    }

    // Registra um novo chamado no Firestore com o número de ticket sequencial
    await addDoc(collection(db, 'chamados'), {
      ticketNumber: ticketNumber,
      created: new Date(),
      cliente: customers[customerSelected].nomeFantasia,
      clienteId: customers[customerSelected].id,
      assunto,
      complemento,
      status,
      userId: user.uid,
    })
      .then(() => {
        setShowModal(true);
        setComplemento('');
        setCustomerSelected(0);
      })
      .catch((error) => {
        toast.error('Erro ao registrar o chamado.');
        console.log(error);
      });
  }

  function closeModal() {
    setShowModal(false);
    navigate('/dashboard');
  }

  return (
    <div>
      <Header />
      <div className="content">
        <Title name={id ? 'Editando chamado' : 'Novo chamado'}>
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">
          <form className="form-profile" onSubmit={handleRegister}>
            <label>Clientes</label>
            {loadCustomer ? (
              <input type="text" disabled={true} value="Carregando..." />
            ) : (
              <select value={customerSelected} onChange={hnadleChangeCustomer}>
                {customers.map((item, index) => (
                  <option key={index} value={index}>
                    {item.nomeFantasia}
                  </option>
                ))}
              </select>
            )}

            <label>Assunto</label>
            <select value={assunto} onChange={handleChangeSelect}>
              <option value="Suporte">Suporte</option>
              <option value="Visita Tecnica">Visita Técnica</option>
              <option value="Financeiro">Financeiro</option>
            </select>

            <label>Status</label>
            <div className="status">
              <input
                type="radio"
                name="radio"
                value="Aberto"
                onChange={handleOptionChange}
                checked={status === 'Aberto'}
              />
              <span>Em aberto</span>

              <input
                type="radio"
                name="radio"
                value="Progresso"
                onChange={handleOptionChange}
                checked={status === 'Progresso'}
              />
              <span>Progresso</span>

              <input
                type="radio"
                name="radio"
                value="Atendido"
                onChange={handleOptionChange}
                checked={status === 'Atendido'}
              />
              <span>Atendido</span>
            </div>

            <label>Complemento</label>
            <textarea
              type="text"
              placeholder="Descreva seu problema (opcional)."
              value={complemento}
              onChange={(e) => setComplemento(e.target.value)}
            />

            <button type="submit">Registrar</button>
          </form>
        </div>
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Chamado Criado!</h2>
            <p>
              O número do seu ticket é: <strong>{ticketNumber}</strong>
            </p>
            <button onClick={closeModal}>Fechar</button>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}
