import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/auth';

import Header from '../../components/Header';
import Title from '../../components/Title';
import Footer from '../../components/Footer';
import { FiPlus, FiMessageSquare, FiSearch, FiEdit2 } from 'react-icons/fi';

import { Link } from 'react-router-dom';
import {
  collection,
  getDocs,
  orderBy,
  limit,
  startAfter,
  query,
} from 'firebase/firestore';
import { db } from '../../services/firebaseConnection';

import { format } from 'date-fns';
import Modal from '../../components/Modal';

import './dashboard.css';

// Referência da coleção "chamados" no Firestore
const listRef = collection(db, 'chamados');

export default function Dashboard() {
  const { logout } = useContext(AuthContext); // Contexto de autenticação para usar o logout

  // Estados para armazenar dados e controlar a interface
  const [chamados, setChamados] = useState([]); // Lista de chamados
  const [loading, setLoading] = useState(true); // Controla o carregamento inicial

  const [isEmpty, setIsEmpty] = useState(false); // Verifica se há mais chamados para carregar
  const [lastDocs, setLastDocs] = useState(); // Armazena o último documento carregado para paginação
  const [loadingMore, setLoadingMore] = useState(false); // Controla o carregamento de mais chamados

  const [showPostModal, setShowPostModal] = useState(false); // Controla a exibição do modal de detalhes
  const [detail, setDetail] = useState(); // Dados do chamado selecionado para exibir no modal

  // Carrega os primeiros chamados ao montar o componente
  useEffect(() => {
    async function loadChamados() {
      // Define uma consulta para ordenar por data de criação e limitar a 5 resultados
      const q = query(listRef, orderBy('created', 'desc'), limit(5));

      const querySnapshot = await getDocs(q);
      setChamados([]);

      await updateState(querySnapshot); // Atualiza o estado com os dados dos chamados

      setLoading(false); // Conclui o carregamento inicial
    }

    loadChamados();

    return () => {};
  }, []);

  // Atualiza o estado dos chamados a partir de uma consulta do Firestore
  async function updateState(querySnapshot) {
    const isCollectionEmpty = querySnapshot.size === 0;

    if (!isCollectionEmpty) {
      let lista = [];

      querySnapshot.forEach((doc) => {
        lista.push({
          id: doc.id,
          ticketNumber: doc.data().ticketNumber, // Adiciona o número do ticket
          assunto: doc.data().assunto,
          cliente: doc.data().cliente,
          clienteId: doc.data().clienteId,
          created: doc.data().created,
          createdFormat: format(doc.data().created.toDate(), 'dd/MM/yyyy'), // Formata a data
          status: doc.data().status,
          complemento: doc.data().complemento,
        });
      });

      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1]; // Último documento para paginação

      setChamados((chamados) => [...chamados, ...lista]); // Atualiza a lista de chamados
      setLastDocs(lastDoc); // Armazena o último documento para a próxima consulta
    } else {
      setIsEmpty(true); // Indica que não há mais documentos
    }

    setLoadingMore(false); // Finaliza o carregamento de mais chamados
  }

  // Função para carregar mais chamados ao clicar no botão
  async function handleMore() {
    setLoadingMore(true); // Inicia o carregamento de mais chamados

    // Consulta para carregar mais chamados, iniciando após o último carregado
    const q = query(
      listRef,
      orderBy('created', 'desc'),
      startAfter(lastDocs),
      limit(5),
    );
    const querySnapshot = await getDocs(q);
    await updateState(querySnapshot); // Atualiza o estado com novos chamados
  }

  // Função para abrir/fechar o modal de detalhes
  function toggleModal(item) {
    setShowPostModal(!showPostModal); // Alterna a exibição do modal
    setDetail(item); // Define o chamado selecionado para exibir no modal
  }

  // Renderiza uma mensagem enquanto os chamados estão sendo carregados
  if (loading) {
    return (
      <div>
        <Header />

        <div className="content">
          <Title name="Tickets">
            <FiMessageSquare size={25} />
          </Title>

          <div className="container dashboard">
            <span>Buscando chamados...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header />

      <div className="content">
        <Title name="Tickets">
          <FiMessageSquare size={25} />
        </Title>

        <>
          {/* Renderiza um link para novo chamado ou a lista de chamados */}
          {chamados.length === 0 ? (
            <div className="container dashboard">
              <span>Nenhum chamado encontrado...</span>
              <Link to="/new" className="new">
                <FiPlus color="#FFF" size={25} />
                Novo chamado
              </Link>
            </div>
          ) : (
            <>
              <Link to="/new" className="new">
                <FiPlus color="#FFF" size={25} />
                Novo chamado
              </Link>

              <table>
                <thead>
                  <tr>
                    <th scope="col">Ticket</th>{' '}
                    {/* Cabeçalho para o número do ticket */}
                    <th scope="col">Cliente</th>
                    <th scope="col">Assunto</th>
                    <th scope="col">Status</th>
                    <th scope="col">Cadastrado em</th>
                    <th scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {chamados.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td data-label="Ticket">{item.ticketNumber}</td>{' '}
                        {/* Exibe o número do ticket */}
                        <td data-label="Cliente">{item.cliente}</td>
                        <td data-label="Assunto">{item.assunto}</td>
                        <td data-label="Status">
                          <span
                            className="badge"
                            style={{
                              backgroundColor:
                                item.status === 'Aberto' ? '#5cb85c' : '#999',
                            }}
                          >
                            {item.status}
                          </span>
                        </td>
                        <td data-label="Cadastrado">{item.createdFormat}</td>
                        <td data-label="Ações">
                          <button
                            className="action"
                            style={{ backgroundColor: '#3583f6' }}
                            onClick={() => toggleModal(item)}
                          >
                            <FiSearch color="#FFF" size={17} />
                          </button>
                          <Link
                            to={`/new/${item.id}`}
                            className="action"
                            style={{ backgroundColor: '#f6a935' }}
                          >
                            <FiEdit2 color="#FFF" size={17} />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {loadingMore && <h3>Buscando mais chamados...</h3>}
              {!loadingMore && !isEmpty && (
                <button className="btn-more" onClick={handleMore}>
                  Buscar mais
                </button>
              )}
            </>
          )}
        </>
      </div>

      {/* Modal para exibir os detalhes do chamado */}
      {showPostModal && (
        <Modal
          conteudo={detail}
          close={() => setShowPostModal(!showPostModal)}
        />
      )}

      <Footer />
    </div>
  );
}
